<!-- 新版客户端 => 基本信息 => 资料上传 -->
<template>
  <div class="write_info">
    <div class="info">
      <div class="info_title">
        <span class="iconfont icon-sfrz-icon"></span>
        <span>资料上传</span>
      </div>
      <div class="info_form">
        <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <!-- text -->
          <el-form-item
                      v-for="(item, index) in itemList"
                      :label="item.label"
                      :prop="item.model"
                      :key="index"
          >
            <el-input v-if="item.type === 'text'" v-model.trim="form[item.model]" :placeholder="item.pla" :maxlength="item.max"></el-input>
            <!-- select -->
            <el-select
              v-if="item.type === 'select'"
              v-model="form[item.model]"
              :disabled="isDisabled"
              :popper-append-to-body="false"
              :placeholder="item.pla"
              no-data-text="无数据"
              @change="setAddress(item.model)"
            >
              <el-option v-for="(lable,key) in selectObj[item.list]" :label="lable.name" :value="lable.value" :key="key"></el-option>
            </el-select>
          </el-form-item>
          <!-- 上传部分 -->
          <el-form-item label="上传身份证" prop="idCardImg">
            <div class="shade" v-if="!(form.user_name && form.user_idno)" @click="evWarn"></div>
            <my-upload
              ref="card"
              parName="idCardImg"
              @setFormUrlId="setFormUrlId"
              :customBackImg="oIdCordImg"
              class="id_card"
              :data="{ 'idno_type': 1, 'user_name': form.user_name, 'user_idno': form.user_idno }"
            />
            <my-upload ref="back" parName="backIdCardImg" @setFormUrlId="setFormUrlId" :customBackImg="oBackIdCordImg" />
            <el-input v-show="false" v-model.trim="form.idCardImg"></el-input>
          </el-form-item>
          <el-form-item label="社保证明" prop="social_security">
            <span class="social_ps">注意事项:
              <br />1、社保证明必备要素:姓名、身份证号、当前缴费单位及缴费月数;
              <br />2、社保证明上必须有鲜章或者社保网站验证码;
              <br />3、如一张图片无法完全展现,请上传多张证明图。
            </span>
            <my-upload ref="social" :pieces="5" parName="social_security" @setFormUrlId="setFormUrlId" />
            <el-input v-show="false" v-model.trim="form.social_security"></el-input>
            <div class="chestnut">
              <span>示例:</span>
              <img :src="require('@/assets/handleBusiness/sl-pic.png')">
            </div>
          </el-form-item>
          <!-- <el-form-item label="话费账单" prop="used_month">
            <my-upload ref="month" parName="used_month" @setFormUrlId="setFormUrlId" />
            <el-input v-show="false" v-model.trim="form.used_month"></el-input>
          </el-form-item> -->
          <el-form-item label="推荐人号码" v-show="form.mobile_type === 2" prop="reference">
            <el-input v-model.trim="form.reference" placeholder="请输入推荐人号码" maxlength="11"></el-input>
          </el-form-item>
          <el-form-item label="其他证明">
            <my-upload :pieces="5" ref="rest" cardType="text" accept="image/*,video/*" parName="other_certificates" @setFormUrlId="setFormUrlId" />
          </el-form-item>
        </el-form>
        <button class="my_button info_form_next" @click="evSubmit">提交</button>
      </div>
    </div>

    <!-- loading -->
    <Loading :isLoading="loading" :text="loadingText" />
  </div>
</template>

<script>
// 接口
import { GetAddressArea, PostSubmitUserData, GetSubmissionInquire, GetAgreementList } from '@/api'
// 组件
import MyUpload from '@/components/Upload'
import Loading from '@/components/Loading'

export default {
  data () {
    return {
      // 参数
      form: {
        province: null,
        city: null,
        area: null
      },
      // loading
      loading: false,
      loadingText: '',
      // 禁用状态
      isDisabled: false,
      // 身份证背景图
      oIdCordImg: require('@/assets/handleBusiness/zm-p2@2x.png'),
      oBackIdCordImg: require('@/assets/handleBusiness/fm-p2@2x.png'),
      selectObj: {
        // 办理类型
        cooperateList: [
          { name: '老号码办理', value: 1 },
          { name: '新号码办理 (使用其他号码办理业务)', value: 2 }
        ],
        // 省份
        provinceList: [],
        // 城市
        cityList: [],
        // 分区
        areaList: []
      },
      // 输入循环显示
      itemList: [
        // { label: '客户本人电话', model: 'mobile', pla: '请输入您的电话号码', type: 'text', max: 11 },
        { label: '省份', model: 'province', pla: '请选择您所在的省份', type: 'select', list: 'provinceList' },
        { label: '城市', model: 'city', pla: '请选择您所在的城市', type: 'select', list: 'cityList' },
        { label: '分区', model: 'area', pla: '请选择您所在的分区', type: 'select', list: 'areaList' },
        { label: '详细地址(例:x街道x号x小区x栋x单元x楼层x门牌号)', model: 'user_adress', pla: '请输入您的居住地址', type: 'text', max: 20 },
        { label: '联系人亲属姓名', model: 'relatives_name', pla: '请输入联系人亲属姓名', type: 'text', max: 20 },
        { label: '联系人亲属号码', model: 'relatives_mobile', pla: '请输入联系人亲属号码', type: 'text', max: 11 },
        { label: '其他联系人姓名', model: 'leader_name', pla: '请输入其他联系人姓名', type: 'text', max: 20 },
        { label: '其他联系人号码', model: 'leader_mobile', pla: '请输入其他联系人号码', type: 'text', max: 11 },
        { label: '办理类型', model: 'mobile_type', pla: '请选择办理类型', type: 'select', list: 'cooperateList' }
      ],
      // 校验
      rules: {
        // mobile: [{ required: true, message: '请输入您的电话号码', trigger: 'blur' }],
        province: [{ required: true, message: '请选择您所在的省份', trigger: 'change' }],
        city: [{ required: true, message: '请选择您所在的城市', trigger: 'change' }],
        area: [{ required: true, message: '请选择您所在的分区', trigger: 'change' }],
        user_adress: [{ required: true, message: '请输入您的居住地址', trigger: 'blur' }],
        relatives_name: [
          { required: true, message: '请输入联系人亲属姓名', trigger: 'blur' },
          { min: 2, message: '请输入联系人全名', trigger: 'blur' }
        ],
        relatives_mobile: [
          { required: true, message: '请输入联系人亲属号码', trigger: 'blur' },
          { min: 11, message: '手机号码不正确', trigger: 'blur' }
        ],
        leader_name: [
          { required: true, message: '请输入其他联系人姓名', trigger: 'blur' },
          { min: 2, message: '请输入联系人全名', trigger: 'blur' }
        ],
        leader_mobile: [
          { required: true, message: '请输入其他联系人号码', trigger: 'blur' },
          { min: 11, message: '手机号码不正确', trigger: 'blur' }
        ],
        reference: [
          { required: false, min: 11, message: '手机号码不正确', trigger: 'blur' }
        ],
        mobile_type: [{ required: true, message: '请选择办理类型', trigger: 'change' }],
        idCardImg: [{ required: true, message: '请上传您的身份证照片', trigger: 'blur' }],
        social_security: [{ required: true, message: '请上传您的社保证明', trigger: 'blur' }]
        // used_month: [{ required: true, message: '请上传您的话费账单', trigger: 'blur' }]
      }
    }
  },
  methods: {
    // 员工办理(接口)
    apiSubmitUserData: function () {
      PostSubmitUserData(this.form)
        .then(({ code, data, msg }) => {
          // console.log('[数据]', data)
          if (code === 1000) {
            this.$message.success(msg)
            // this.$router.push({ 'name': 'User' })
            this.$router.replace('/')
            return
          }
          this.$message.error(msg)
        })
        .finally(() => {
          this.loading = false
        })
    },
    // 查询预添加客户 (接口)
    apiSubmissionInquire: function () {
      const { user_idno, mobile, third_id } = this.form
      GetSubmissionInquire({ user_idno, mobile, third_id })
        .then(({ code, msg, data }) => {
          const oI = data.data[0]
          if (code === 1000 && oI) {
            this.fnEcho(oI)
          }
        })
    },
    // 省市区联动(接口)
    apiAddressArea: async function () {
      const { province, city } = this.form
      let oData = []
      await GetAddressArea({ province, city })
        .then(res => {
          const { code, data, msg } = res
          if (code === 1000) {
            oData = data
          } else {
            this.$message.error(msg)
          }
        })
      return oData
    },
    // 客户订单回显 (接口)
    apiAgreementList: function (id) {
      GetAgreementList({ id, status: '' })
        .then(({ code, msg, data }) => {
          const oI = data.list[0]
          if (code === 1000 && oI) {
            this.fnEcho(oI)
            this.form.id = this.$route.query.edit_id
            return
          }
          this.$message.error(msg)
        })
    },
    // 内容回显 (方法)
    fnEcho: function (oI) {
      const arr = ['province', 'city', 'area', 'user_adress', 'relatives_name', 'relatives_mobile', 'leader_name', 'leader_mobile', 'mobile_type', 'idCardImg', 'backIdCardImg', 'social_security', 'other_certificates']
      for (const key in oI) {
        if (arr.indexOf(key) !== -1) {
          this.form[key] = oI[key]
        }
      }
      // 省份转数字类型
      this.form.province = oI.province || null
      this.setAddress('province')
      this.form.city = oI.city || null
      this.setAddress('city')
      this.form.area = oI.area || null
      this.form.white_id = oI.id
      // 已上传图片展示
      this.$nextTick(() => {
        const _that = this
        this.$refs['card'].imageUrl = oI['idCardImg']
        this.$refs['back'].imageUrl = oI['backIdCardImg']
        if (oI.social_security) {
          urlSplit('social', 'social_security')
        }
        if (oI.other_certificates) {
          urlSplit('rest', 'other_certificates')
        }
        function urlSplit (key, model) {
          _that.$refs[key].insImageUrl = oI[model].split(',').map(item => {
            const oU = item.split('https://oss.newcigar.cn/')
            return { url: item, name: oU[1], response: { data: { url: oU[1], cdnurl: 'https://oss.newcigar.cn/' }}}
          })
        }
      })
    },
    // 省市区设置
    setAddress: async function (model) {
      const sel_type = ['mobile_type']
      if (sel_type.indexOf(model) === -1) {
        switch (model) {
          case 'province':
            Object.assign(this.form, {
              'city': null,
              'area': null
            })
            this.selectObj.cityList = await this.apiAddressArea()
            this.selectObj.areaList = []
            break
          case 'city':
            this.form.area = null
            this.selectObj.areaList = await this.apiAddressArea()
            break
          case 'area':
            break
          default:
            this.selectObj.provinceList = await this.apiAddressArea()
        }
      }
    },
    // 上传图片 id 设置(子调用)
    setFormUrlId: function (res) {
      const { data, code, name, pieces } = res
      if (code !== 1000) return
      if (name === 'social_security') {
        this.form[name] = data.cdnurl + data.url
      } else if (pieces === 1) {
        this.form[name] = data.cdnurl + data.url
      }
    },
    // 提交(校验)
    evSubmit: function () {
      this.$refs['ruleForm'].validate(async (valid) => {
        if (valid) {
          this.loading = true
          this.loadingText = '提交中...'
          this.form['other_certificates'] = await this.fnGetinsImageUrl('rest')
          this.form['social_security'] = await this.fnGetinsImageUrl('social')
          // 员工办理(方法)
          this.apiSubmitUserData()
        } else {
          this.$message.error('请正确填写必填项')
        }
      })
    },
    // 获取其他证明图片地址
    fnGetinsImageUrl: function (title) {
      const insUrlList = this.$refs[title].insImageUrl
      const oGroupUrl = insUrlList.map(item => {
        const { cdnurl, url } = item?.response?.data
        return cdnurl + url
      })
      return oGroupUrl.join(',')
    },
    // 提醒
    evWarn: function () {
      this.$message.warning('请输入姓名与身份证号后再上传身份证')
    }
  },

  created () {
    // console.log('[url]', this.$route.query = { 'user_name': '上官婉儿', 'user_idno': '370705199607049121' })
    const obj = JSON.parse(JSON.stringify(this.$route.query))
    Object.assign(this.form, obj)
    this.selectObj.cooperateList[0].name = `老号码办理 (使用${obj.mobile}号码办理业务)`
    // 地址
    this.setAddress()
  },

  mounted () {
    /**
     * apiSubmissionInquire: 查询预添加客户 (方法)
     * apiAgreementList: 客户订单回显 (方法)
     */
    const oEditId = this.$route.query.edit_id
    oEditId ? this.apiAgreementList(oEditId) : this.apiSubmissionInquire()
  },

  components: {
    MyUpload,
    Loading
  }
}
</script>

<style lang="scss" scoped>
$fontSize: 32px;

.info {
  display: flex;
  flex-direction: column;
  padding: 50px 24px 40px;
  height: 100vh;
  font-size: $fontSize;
  background: url("../../assets/kh-bg@2x.png") no-repeat;
  background-size: 100%;

  &_title {
    margin: 0 auto 20px;
    width: 272px;
    height: 84px;
    line-height: 84px;
    color: #fff;
    font-size: 32px;
    border-radius: 12px;
    background: #FFC060;
    text-align: center;

    .iconfont {
      margin-right: 10px;
      line-height: 36px;
      font-size: 36px;
    }
  }

  &_form {
    position: relative;
    width: 100%;
    flex-grow: 1;
    background: #fff;
    border-radius: 40px 40px 0 0;
    box-shadow: 0 0 20px rgba(87, 95, 102, 0.16);

    &::before {
      content: "";
      display: block;
      overflow: hidden;
      position: absolute;
      top: 40px;
      left: 0;
      width: 100%;
      height: 690px;
      background: transparent;
      border-radius: 25px;
      border: 3px solid #FFC060;
    }

    .demo-ruleForm {
      .id_card {
        margin-bottom: 20px;
      }

      .chestnut {
        text-align: center;

        span {
          display: block;
          margin-top: 20px;
          text-align: left;
          line-height: 70px;
        }

        img {
          width: 512px;
        }
      }
    }

    &_next {
      position: absolute;
      bottom: 54px;
      left: 50%;
      margin-left: -307px;
      letter-spacing: 10px;
      font-weight: 500;
      background: linear-gradient(90deg, #F2970C, #FEC56E);
    }
  }
}
.social_ps {
  display: block;
  margin: 34px 0;
  font-size: 26px;
  color: #F62323;
  line-height: 40px;
}
</style>

<style lang="scss" scoped>
.demo-ruleForm {
  padding: 44px 44px 200px;

  /deep/.el-form-item {
    display: block;
    margin: 0 0 44px;

    .el-form-item__label {
      display: block;
      margin: 16px 0;
      width: 100% !important;
      line-height: normal;
      color: #333;
      font-size: 30px;
      text-align: left;
      font-weight: normal;

      &::before {
        display: inline-block;
        content: '';
        margin-right: 12px;
        width: 8px;
        height: 28px;
        background: #F2970C;
        border-radius: 0 8px 8px 0;
        overflow: hidden;
      }
    }

    .el-form-item__content {
      line-height: normal;

      .el-input {
        .el-input__inner {
          padding: 0 15px;
          height: 60px;
          line-height: 60px;
          font-size: 28px;
          border: 0;
          border-radius: 0;
          border-bottom: 1px solid #EBEBEB;
        }
      }

      .el-select {
        width: 100%;
      }
    }
  }
}

/deep/.el-form-item.is-required:not(.is-no-asterisk)>.el-form-item__label:after {
  content: '*';
  color: var(--el-color-danger);
  margin-left: 4px;
}
.shade {
  position: absolute;
  width: 100%;
  height: 100%;
  background: transparent;
  z-index: 3;
}
</style>
