<template>
  <div class="upload">
    <el-upload
      :class="[cardType === 'text' ? '' : 'avatar-uploader']"
      :action="baseUrl"
      :headers="headers"
      :data="data"
      :accept="accept"
      :list-type="cardType"
      :show-file-list="pieces >= 2"
      :on-success="handleAvatarSuccess"
      :on-remove="handleRemove"
      :before-upload="beforeAvatarUpload"
      :file-list="insImageUrl"
    >
      <div v-if="cardType === 'picture-card'">
        <img v-if="imageUrl && (pieces === 1) && !customBackImg" :src="imageUrl" class="avatar">
        <i v-else-if="!customBackImg" class="iconfont icon-jiahao1 avatar-uploader-icon"></i>
        <img v-else :src="imageUrl || customBackImg" class="back_img">
      </div>
      <div v-else>
        <el-button type="primary" size="small">上传</el-button>
      </div>
    </el-upload>

    <!-- loading -->
    <Loading :isLoading="loading" :text="loadingText" />
  </div>
</template>

<script>
// 组件
import Loading from '@/components/Loading'
// 压缩方法
import tool from '@/utils/tool.js'

export default {
  props: {
    // 可上传张数
    pieces: {
      type: Number,
      default: 1
    },
    // form 上传字段
    parName: {
      type: String
    },
    // 额外参数
    data: {
      type: Object
    },
    // 自定义背景图
    customBackImg: {
      type: String
    },
    // 上传类型 image/*,video/*
    accept: {
      type: String,
      default: 'image/*'
    },
    cardType: {
      type: String,
      default: 'picture-card'
    }
  },
  data () {
    return {
      // 上传地址
      baseUrl: process.env.VUE_APP_BASE_URL + '/api/common/upload',
      // loading
      loading: false,
      loadingText: '上传中...',
      // 图片地址
      imageUrl: '',
      // 多图片列表
      insImageUrl: [],
      // 请求头
      headers: {
        token: localStorage.getItem('token')
      }
    }
  },
  methods: {
    // 上传成功函数钩子
    handleAvatarSuccess (res, file, fileList) {
      this.loading = false
      if (res.code === 1000) {
        Object.assign(res, { 'name': this.parName, 'pieces': this.pieces })
        this.$emit('setFormUrlId', res)
        Object.assign(this, {
          'insImageUrl': fileList,
          'imageUrl': URL.createObjectURL(file.raw)
        })
        return
      }
      this.$message.error(res.msg)
    },
    // 删除函数钩子
    handleRemove (file, fileList) {
      this.insImageUrl = fileList
    },
    // 上传之前函数钩子
    beforeAvatarUpload (file) {
      this.loading = true
      const oSizeFile = this.accept === 'image' ? 10 : 20
      // const isType = ['image/jpeg', 'image/png', 'image/gif', 'video/mp4']
      const isLt2M = file.size / 1024 / 1024 < oSizeFile
      // const isJPG = isType.indexOf(file.type) !== -1
      // if (!isJPG) {
      //   this.$message.error('请上传图片与视频!')
      // }
      if (!isLt2M) {
        this.$message.error(`上传文件大小不能超过 ${oSizeFile}MB!`)
        this.loading = false
        return false
      }
      // if (!(isJPG && isLt2M)) {
      //   return false
      // } else {
        const format = ['video/mp4', 'video/quicktime']
        if (format.indexOf(file.type) !== -1) {
          return file
        } else {
          return new Promise((resolve, reject) => {
            tool.imgPreview(file, (newfileex) => {
              // console.log('[压缩后]', newfileex)
              resolve(newfileex)
            })
          })
        }
      // }
    }
  },
  components: {
    Loading
  }
}
</script>

<style lang="scss" scoped>
$imgWidth: 244px;

/deep/.avatar-uploader {
  .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    &:hover {
      border-color: #409EFF;
    }
    // 占位 icon
    .avatar-uploader-icon {
      margin: 0;
      display: block;
      font-size: 40px;
      color: #8c939d;
      width: $imgWidth;
      height: $imgWidth;
      line-height: $imgWidth;
      text-align: center;
    }
    // 头像图
    .avatar {
      width: $imgWidth;
      height: $imgWidth;
      display: block;
    }
    &.el-upload--picture-card {
      width: auto;
      height: auto;
    }
    // 背景图
    .back_img {
      display: block;
      margin: 0 auto;
      width: 512px;
    }
  }
  /deep/.el-upload-list__item {
    width: $imgWidth;
    height: $imgWidth;
  }
}
</style>
