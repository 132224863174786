const ysApi = {
    /**
     * 图片压缩
     * @param {*} file 文件
     * @param {*} callback 回调函数
     * @returns 图片压缩, 小于 1MB 时, 不进行压缩
     */
    imgPreview (file, callback) {
        const image = new Image()
        let resultBlob = null
        image.src = URL.createObjectURL(file)
        if (file.size <= (1024 * 1024 * 1)) {
            if (callback) {
                callback(file)
            }
            return
        }
        image.onload = () => {
            // 调用方法获取blob格式, 方法写在下边
            resultBlob = this.compressUpload(image, file)
            const fs = new File([resultBlob], file.name, {
                type: file.type
            })
            if (callback) callback(fs)
        }
    },

    /**
     * 图片压缩方法-canvas压缩
     * @param {*} img blob 图片格式
     * @returns
     */
    compressUpload (img) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')
        // 瓦片canvas
        const tCanvas = document.createElement('canvas')
        const tctx = tCanvas.getContext('2d')
        // let initSize = img.src.length
        let width = img.width
        let height = img.height
        // 如果图片大于四百万像素, 计算压缩比并将大小压至400万以下
        let ratio
        if ((ratio = width * height / 4000000) > 1) {
            // console.log("大于400万像素")
            ratio = Math.sqrt(ratio)
            width /= ratio
            height /= ratio
        } else {
            ratio = 1
        }
        canvas.width = width
        canvas.height = height
        // 铺底色
        ctx.fillStyle = '#fff'
        ctx.fillRect(0, 0, canvas.width, canvas.height)
        // 如果图片像素大于100万则使用瓦片绘制
        let count
        if ((count = width * height / 1000000) > 1) {
            // console.log('超过100W像素')
            count = ~~(Math.sqrt(count) + 1) // 计算要分成多少块瓦片
            const nw = ~~(width / count) // 计算每块瓦片的宽和高
            const nh = ~~(height / count)
            tCanvas.width = nw
            tCanvas.height = nh
            for (let i = 0; i < count; i++) {
                for (let j = 0; j < count; j++) {
                    tctx.drawImage(img, i * nw * ratio, j * nh * ratio, nw * ratio, nh * ratio, 0, 0, nw, nh)
                    ctx.drawImage(tCanvas, i * nw, j * nh, nw, nh)
                }
            }
        } else {
            ctx.drawImage(img, 0, 0, width, height)
        }
        const compressData = canvas.toDataURL('image/jpeg', 0.8) // 进行最小压缩
        tCanvas.width = tCanvas.height = canvas.width = canvas.height = 0
        const blobImg = this.byteString(compressData)
        return blobImg
    },

    /**
     * 对 base64 格式进行编码城 blob 格式
     * @param {*} base64 base64 格式
     * @returns
     */
    byteString (base64) {
        var bytes = window.atob(base64.split(',')[1]) // 这里对base64串进行操作, 去掉url头, 并转换为byte

        var ab = new ArrayBuffer(bytes.length) // 处理异常, 将ASCII码小于0的转换为大于0, 这里有两种写法
        var ia = new Uint8Array(ab)
        for (let i = 0; i < bytes.length; i++) {
            ia[i] = bytes.charCodeAt(i) // 这里有点疑惑, ia是怎么改变ab的？注: ①
        }
        // Blob对象
        var blob = new Blob([ab], { type: 'image/jpeg' }) // type为图片的格式

        return blob
    }
}

export default ysApi
